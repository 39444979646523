import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import moment from "moment"
import bg from "../../assets/images/banner/bnr5.jpg"
import { FormFeedback, FormGroup, Input, Label, Form } from "reactstrap"
import { Modal, Spinner, Button } from "react-bootstrap"
import { IMAGES } from "../../constant/theme"
import { useFormik } from "formik"
import { Event_Cover_Image_Api } from "../../helpers/Api_help"
import * as Yup from "yup"
import PageBanner from "../../layouts/PageBanner"
import {
  fetchUpcomingEvents,
  Ticketbookingshow,
} from "../../features/Event/EventTBSlice"
import Landspace from "../../assets/images/banner/SATS-BACKDROP-1600px.png"
import { useDispatch, useSelector } from "react-redux"
import { Card, ListGroup, CardBody, ListGroupItem } from "reactstrap"
import ComingSoon from "../ComingSoon"
import EventsHome from "../../components/Home3/EventsHome"

export const Venue = ({ Venueslist, SetModalMember, Event }) => {
  const User_Id_ = JSON.parse(localStorage.getItem("user_id"))

  // let venue = props.Venueslist
  const dispatch = useDispatch()
  const nav = useNavigate()

  const { UpcomingData } = useSelector((state) => state.EventTBReducer)

  return (
    <ListGroup variant="flush">
      <ListGroupItem className="card-text d-flex justify-content-between">
        <h5>{Venueslist.venue}</h5>
        <Link
          to={"#"}
          className="btn btn-info btn-sm"
          onClick={(e) => {
            e.preventDefault()
            {
              User_Id_ ? nav("/ticketbooking") : SetModalMember(true)
            }
            // localStorage.setItem(
            //   "venueIdPq",
            //   JSON.stringify({ master_venue_id: venue._id })
            // )
            // navigate("/seatB")
            // dispatch(Ticketbookingshow({ master_venue_id: venue._id }))
            localStorage.setItem(
              "SelectVenue",
              JSON.stringify({
                _id: Venueslist._id,
                event_name: Event?.name,
                venue_name: Venueslist?.venue,
                event_date: Event?.start_date,
                venue_date: Venueslist?.start_datetime,
              })
            )
          }}
        >
          Ticket Booking
        </Link>
      </ListGroupItem>
      <ListGroupItem>
        <p className="m-0">Maximum Tickets : {Venueslist.max_tickets}</p>
        <p className="m-0">
          Started Time :{" "}
          {moment(Venueslist.start_datetime)
            .tz("America/Chicago")
            .format("h:mm:ss a")}
        </p>
        <p className="m-0 fw-bold">Address:</p>
        <ul className="ms-5">
          <li>{Venueslist?.address},</li>
          <li>
            {Venueslist?.city},{Venueslist?.state},
          </li>
          <li>
            {Venueslist?.country},{Venueslist.zipcode}.
          </li>
          <li></li>
        </ul>
      </ListGroupItem>
    </ListGroup>
  )
}

const UpComingEvent = () => {
  const { TBisSuccess, UpcomingData } = useSelector(
    (state) => state.EventTBReducer
  )

  const dispatch = useDispatch()
  const nav = useNavigate()
  useEffect(() => {
    dispatch(fetchUpcomingEvents())
  }, [])
  const [modalMember, SetModalMember] = useState(false)
  // console.log(UpcomingData)

  //   const cardBlog = [
  //     {image1: pic4,  tage:"HEALTH",title:"Partnering to create a community", autor:"Hawkins Junior"},
  //     {image1: pic3,  tage:"TECHNOLOGY",title:"4 Things parents learned for they jids in 2020", autor:"Richard Hartisona"},
  //     {image1: pic2,  tage:"EDUCATION",title:"He Created the Web. Now He’s Out to Remake", autor:"Tom wilson"},
  //     {image1: pic1,  tage:"FASHION",title:"New vaccine for cattle calf loss learned", autor:"Adam Jordon"},
  //     {image1: pic5,  tage:"DESIGN",title:"Partnering to create a community", autor:"Kaylynn Donin"},
  // ];
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      category: "",
    },
    validationSchema: Yup.object({
      category: Yup.string().required(" select Your Category type "),
    }),
    onSubmit: (values, actions) => {
      console.log("reset", values)

      if (values.category === "Member") {
        alert("Login and Avail Membership Price for Booking Tickets")
        nav("/login")
      } else {
        nav("/ticketbooking")
      }

      actions.resetForm()
      SetModalMember(false)
    },
  })
  const SelectEvent = (e, data) => {
    console.log("edit")
    e.preventDefault()
    localStorage.setItem("SelectEvent", data)
    nav("/EventsDetails")
  }

  return (
    <>
      <div className="page-content bg-white">
        <PageBanner
          maintitle="Home"
          pagetitle=" Upcoming Events "
          bgColor={"red"}
        />

        <div>
          {UpcomingData.length > 0 ? (
            <section className="pt-5 pb-5">
              <div className="container">
                <div className="section-head d-flex align-items-center flex-column mb-0">
                  <EventsHome UpcomingData={UpcomingData} />
                  <Link
                    to={"pastEvent"}
                    className="btn btn-warning btn-sm mt-2"
                  >
                    Past Events
                  </Link>
                </div>
              </div>
            </section>
          ) : (
            <section className="pt-5 pb-5">
              <div className="container">
                <div className="section-head d-flex align-items-center flex-column mb-0">
                  <p>
                    Stay connected for upcoming events. Till that vist our past
                    events.
                  </p>
                  <Link to={"pastEvent"} className="btn btn-warning btn-sm">
                    Past Events
                  </Link>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
      <Modal
        className="modal fade modal-wrapper auth-modal"
        show={modalMember}
        onHide={() => SetModalMember(false)}
        backdrop="static"
        centered
      >
        <h2 className="title text-warning">Are you ?</h2>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            validation.handleSubmit()
          }}
        >
          {/* <div className="mb-3 col-12">
            <Label htmlFor="category">Category</Label>
            <select
              id="category"
              name="category"
              // className="form-select"
              className={`form-select ${
                validation.touched.category &&
                validation.errors.category &&
                "is-invalid"
              }`}
              defaultValue={validation.values.category}
              onChange={(e) =>
                validation.setFieldValue("category", e.target.value)
              }
            >
              <option value={""}>Select Category </option>

              <option value={"Member"}>Member</option>
              <option value={"Non-Member"}>Non-Member</option>
            </select>
            {validation.touched.category && validation.errors.category ? (
              <FormFeedback>{validation.errors.category}</FormFeedback>
            ) : null}
          </div> */}

          <div className="mb-3 col-12 col-sm-12">
            {/* <Label htmlFor="category">
              Category <span className="text-danger">*</span>
            </Label> */}
            <fieldset className="d-flex flex-column">
              <div
                className="p-3 border-1 border-warning mb-3"
                style={{ borderRadius: 5 }}
              >
                <input
                  type="radio"
                  name="category"
                  id="Member"
                  className="me-2 form-check-input "
                  value={validation.values.category || ""}
                  defaultChecked={validation.values.category === "Member"}
                  onClick={(e) => {
                    validation.setFieldValue("category", "Member")
                  }}
                />
                <label htmlFor="Member" className="me-3">
                  Member
                </label>
              </div>
              <div
                className="p-3 border-1 border-warning mb-3"
                style={{ borderRadius: 5 }}
              >
                <input
                  type="radio"
                  name="category"
                  className="me-2 form-check-input"
                  id="Non-Member"
                  value={validation.values.category || ""}
                  defaultChecked={validation.values.category === "Non-Member"}
                  onClick={(e) => {
                    validation.setFieldValue("category", "Non-Member")
                  }}
                />
                <label htmlFor="Non-Member" className="me-3">
                  Non-Member
                </label>
              </div>
            </fieldset>
            {validation.touched.category && validation.errors.category ? (
              <div
                style={{
                  fontSize: "12px",
                  color: "red",
                }}
              >
                {validation.errors.category}
              </div>
            ) : null}
          </div>
          <div className="sign-text">
            <span>
              <button
                className="btn btn-outline-primary btn-block"
                data-bs-toggle="collapse"
                type="submit"
              >
                Submit
              </button>
            </span>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default UpComingEvent
